import * as Sentry from "@sentry/browser";
// import { getProfilingDocumentHeaderConfigurationStep } from 'sentry/components/onboarding/gettingStartedDoc/utils/profilingOnboarding';

Sentry.init({
  dsn: "https://8c9e04466d18c7e38001cb4e15e27526@o4507702060318720.ingest.de.sentry.io/4507702066085968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/taya\.today/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Release version
  release: "tayatoday-web@0.10.4",
  environment: "prod"
})